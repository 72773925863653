import React, { useEffect, useMemo } from 'react';
import {
  Grid, Paper, Box, TextField, Typography,
  Button 
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useForm, Controller } from "react-hook-form";

const AccountInfo = ({ accountDetails, updateProfileInfo }) => {

  /** Hooks */
  const { handleSubmit, control, formState: { errors }, reset } = useForm({
    mode: 'onTouched',
    defaultValues: {...accountDetails}
  });

  useEffect(() => {
    reset({...accountDetails})
  }, [accountDetails, reset])

  const onSubmit = (data) => {
    console.log('submit')
    updateProfileInfo({...data });
  }
  
  const disabled = useMemo(() => (accountDetails?.checkin === 'Y'), [accountDetails.checkin])

  return ( 
    <Paper  sx={{mt: 0,boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'}}>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Box noValidate component="form"  onSubmit={handleSubmit(onSubmit)} sx={{  p:4,  }} >
          <Typography variant="subtitle2"  sx={{mb: 2}}>
            Update account details
            { disabled ? <Box component="small" sx={{  display:'block', color: 'red' }}>*Please contact your PG owner to update profile details</Box> : null}
          </Typography>
          <Grid container columnSpacing={2} rowSpacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6}  >
              <Controller
                control={control}
                name="name"
                id="name"
                rules={{ required: "Enter your name" }}
                render={({field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    size="small"
                    autoFocus
                    label="Name"
                    required
                    disabled={disabled}
                    error={errors.name?.type === 'required'}
                    // helperText={errors.firstName?.message}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="mobile"
                id="mobile"
                rules={{ required: "Enter mobile number" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Mobile Number"
                    disabled={true}
                    // required
                    // error={errors.lastName?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="email"
                id="email"
                readOnly
                rules={{ required: "Enter your email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid email address",
                  }, 
                }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Email"
                    required
                    error={errors.email?.type === 'required'}
                    // helperText={errors.email?.message}
                    disabled={disabled}
                    size="small"
                    fullWidth
                    readOnly
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="emergencyContactName"
                id="ec_name"
                rules={{ required: "Enter emergency contact name" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Emergency Contact Name"
                    required
                    error={errors.emergencyContactName?.type === 'required'}
                    // helperText={errors.mobile?.message}
                    disabled={disabled}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="emergencyContactNumber"
                id="ec_contact_number"
                rules={{ required: "Enter emergency contact number" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Emergency Contact Number"
                    disabled={disabled}
                    required
                    error={errors.emergencyContactNumber?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Controller
                control={control}
                name="address"
                id="address"
                rules={{ required: "Enter address" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    multiline
                    margin="normal"
                    label="Address"
                    minRows={2}
                    disabled={disabled}
                    required
                    error={errors.address?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="workplaceCollege"
                id="company_college"
                // rules={{ required: "Enter workplace/college" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Workplace/College"
                    // required
                    // error={errors.company_college?.type === 'required'}
                    // helperText={errors.mobile?.message}
                    disabled={disabled}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="workplaceCollegeAddress"
                id="company_college_location"
                // rules={{ required: "Enter company/college location" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Workplace/College Location"
                    // required
                    // error={errors.company_college_location?.type === 'required'}
                    // helperText={errors.mobile?.message}
                    disabled={disabled}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="reference1"
                id="reference_1"
                // rules={{ required: "Enter reference name" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Reference 1"
                    // required
                    // error={errors.reference_1?.type === 'required'}
                    // helperText={errors.mobile?.message}
                    disabled={disabled}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="reference1Number"
                id="reference_1_number"
                // rules={{ required: "Enter reference contact number" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Reference 1 Mobile Number"
                    disabled={disabled}
                    // required
                    // error={errors.reference_1_number?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="reference2"
                id="reference_2"
                // rules={{ required: "Enter reference name" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Reference 2"
                    // required
                    // error={errors.reference_2?.type === 'required'}
                    // helperText={errors.mobile?.message}
                    disabled={disabled}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Controller
                control={control}
                name="reference2Number"
                id="reference_2_number"
                // rules={{ required: "Enter reference contact number" }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    label="Reference 2 Mobile Number"
                    disabled={disabled}
                    // required
                    // error={errors.lastName?.type === 'required'}
                    // helperText={errors.lastName?.message}
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            { disabled ? null :(
              <Grid
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 6, mb: 2 }}
                  startIcon={<EditIcon />}
                  color="success"
                >
                  Update Account
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
}
 
export default AccountInfo;
